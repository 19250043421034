@tailwind base;
@tailwind components;
@tailwind utilities;
.profile-div {
  display: flex;
  max-width: 1024px;
  justify-content: center;
  align-items: center;
  margin: 0 8%;
  padding: 64px 0;
}

.particle-bg {
  display: none;
}

.profileName,
.NMLS {
  color: #004e82;
  margin: 0;
}

.profileName {
  margin-top: 16px;
}

.NMLS {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 400;
}

.troyProfilePic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(145, 149, 157, 0.3);
}

@media (min-width: 768px) {
  .profile-div {
    padding: 8%;
    margin: 0 auto;
    height: calc(100vh - 240px);
  }

  .profileName {
    margin-top: 0px;
  }

  .particle-bg {
    display: block;
  }

  .profile-grid {
    display: grid;
    gap: 8%;
    align-items: start;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    background: transparent;
  }

  .troyProfilePic {
    padding-top: 0px;
  }
}

@media (min-width: 1024px) {
  .profile-grid {
    align-items: center;
  }
}
