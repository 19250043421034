/* Global Styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "SourceSansPro-Black", Helvetica, Arial;
  font-size: 24px;
  font-weight: 900;
  color: #004e82;
  letter-spacing: 0.25px;
}

h2 {
  font-family: "SourceSansPro-Black", Helvetica, Arial;
  font-size: 24px;
  font-weight: 900;
  color: #004e82;
  letter-spacing: 1px;
}

h3 {
  font-family: "SourceSansPro-Black", Helvetica, Arial;
  font-size: 24px;
  font-weight: 900;
  color: #004e82;
  letter-spacing: 1px;
}

p,
a,
li {
  font-family: "OpenSans", Helvetica, Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #91959d;
  line-height: 1.5;
  letter-spacing: 0.75px;
  margin-top: 16px;
}

a {
  text-decoration: none;
  color: #2ca4f2;
}

.buttonBlue {
  width: 100%;
  margin-top: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #2ca4f2;
  border: 1px solid #2ca4f2;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "SourceSansPro-Black", Helvetica, Arial;
  font-size: 18px;
  font-weight: 900;
  outline: none;
  outline: 0;
  padding: 8px 32px;
  text-align: center;
  transform: translateY(0);
  user-select: none;
  -webkit-user-select: none;
  transition: 0.3s;
}

.buttonBlue:hover {
  box-shadow: 0px 8px 16px rgba(145, 149, 157, 0.3);
  transform: translateY(-2px);
  /* transition: all 3s ease-in-out; */
}

@media (min-width: 1024px) {
  .buttonBlue {
    width: fit-content;
    margin: 32px 0 0;
    padding: 16px 32px;
    font-size: 24px;
  }
}
